import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageTitle from "components/PageTitle";
import { Link } from "react-router-dom";
import { ASSETS_DIRECTORY, INSTAGRAM_PROFILE_LINK, LINKED_PROFILE_LINK } from "utils/config";

const Contact = () => (
  <div className="">
    <div className="flex flex-col-reverse lg:flex-row md:gap-8">
      <img
        src={`${ASSETS_DIRECTORY}/contact.jpg`}
        className="w-full lg:w-2/5"
        loading="lazy"
      />
      <div>
        <PageTitle>Contact</PageTitle><br/><br/>
        <div className="p-8 md:p-0 text-base border-box">
          <p className="text-center">
            Si j'avais un conseil à vous donner : osez ! N'ayez crainte de
            mélanger les textures, les couleurs et les motifs, ils sauront
            rendre votre intérieur unique et à votre image !{" "}
          </p><br/>
          <p className="text-center font-semibold">contact@izdecoration.com</p><br/>
          <div className="flex w-full justify-center gap-6">
            <Link to={INSTAGRAM_PROFILE_LINK} target="_blank">
                <FontAwesomeIcon className="fa-2xl" icon={faInstagram} />
            </Link>
            <Link to={LINKED_PROFILE_LINK} target="_blank">
                <FontAwesomeIcon className="fa-2xl" icon={faLinkedin} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Contact;

import { Link } from "react-router-dom"

const ProjectCover = ({
  title,
  coverImage,
  projectId
}: {
  title: string
  coverImage: string
  projectId: string
}) => (
  <Link to={`/projects/${projectId}`}>
    <div className="relative w-full">
      <img src={coverImage} className="w-full h-full" loading="lazy"/>
      <div className="absolute inset-0 flex items-center justify-center text-white font-extrabold">
        <p className="font-extrabold text-[64px]">{title}</p>
      </div>
    </div>
  </Link>
)

export default ProjectCover

import classNames from "classnames";
import { memo, ReactNode } from "react";
import { Link } from "react-router-dom";

const MenuLink = memo(
  ({
    path,
    isActive = false,
    openInNewTab = false,
    className,
    children,
  }: {
    path: string;
    isActive?: boolean;
    openInNewTab?: boolean;
    className?: string;
    children: ReactNode;
  }) => (
    <div
      className={classNames(className, { "border-b border-black font-semibold": isActive })}
    >
      <Link to={path} target={openInNewTab ? "_blank" : "_self"}>
        {children}
      </Link>
    </div>
  )
);

export default MenuLink


import data from 'data.json'

export const ASSETS_DIRECTORY = "/assets"

export const PROJECTS_ASSETS_DIRECTORY = "/public/assets/projects"

export const INSTAGRAM_PROFILE_LINK = "https://www.instagram.com/izdecoration/"

export const LINKED_PROFILE_LINK = "https://www.linkedin.com/in/illana-zylberberg-0b7760171/"

export const PROJECTS_NAME_LIST = ["Alma", "Odeon", "Inkermann", "Theodore_de_Banville", "Ternes", "Spontini", "Marais", "Foch", "Bosquet"] as const

export type PROJECTS_NAMES = typeof PROJECTS_NAME_LIST[number]

export const PROJECTS: Record<string, { name: string, description: string, cover: string, images: string[]}> = data

import { Route, Routes } from 'react-router-dom'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Home from 'pages/Home'
import Contact from 'pages/Contact'
import Projects from 'pages/Projects'
import ProjectDetailPage from 'pages/ProjectDetailPage'

function App() {
  return (
    <div className="md:px-16" style={{fontFamily: "Montserrat"}}>
      <Header />
      <Routes>
        <Route key="home" path="/" element={<Home />} />
        <Route key="projects" path="/projects" element={<Projects />} />
        <Route key="projetsDetails" path="/projects/:projectName" element={<ProjectDetailPage />} />
        <Route key="contact" path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default App

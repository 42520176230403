import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom'
import { INSTAGRAM_PROFILE_LINK } from 'utils/config'

const Footer = () => (
  <div className="flex justify-between p-8">
    <a href="/contact">
      <div className="text-lg font-semibold font-medium">
        Illana <span className="uppercase">Zylberberg</span>
      </div>
      <div>Décoration et design d’intérieur</div>
    </a>
    <Link to={INSTAGRAM_PROFILE_LINK} target="_blank">
      <FontAwesomeIcon className="fa-2xl" icon={faInstagram} />
    </Link>
  </div>
)

export default Footer

import PageTitle from 'components/PageTitle'
import { ASSETS_DIRECTORY } from 'utils/config'

const Home = () => (
  <div className="">
    <div className="flex flex-col lg:flex-row md:gap-8">
      <img src={`${ASSETS_DIRECTORY}/portrait.jpg`} className="w-full lg:w-2/5" loading="lazy"/>
      <div>
        <PageTitle>L'agence</PageTitle>
        <div className="p-8 md:p-0 text-base border-box">
          <p>
            Forte d'expériences acquises lors de stages dans diverses branches
            du monde de la décoration et après plus de trois années passées aux
            côtés de Vanessa Faivre, aujourd'hui décoratrice d’intérieur
            diplômée de l’École Supérieure d’Architecture d’Intérieur de Lyon
            depuis 2020, j’ai pu accompagner et accompagne des clients dans
            leurs projets de rénovation.
          </p>
          <br />
          <p>
            Sensible aux couleurs, aux textures, et aux motifs, j’ai un goût
            prononcé pour les intérieurs éclectiques qui associent l’éloquence
            de l’ancien et le contemporain. J’affectionne tout particulièrement
            de mêler le masculin et le féminin afin de créer une atmosphère à la
            fois distinguée et accueillante.{' '}
          </p>
          <br />
          <p>
            Rendre ou donner une âme à un lieu de vie, tel est le fil conducteur
            de mon métier.
          </p>
          <br />
          <p>
            “Rien ne reflète mieux l’âme des gens que le lieu dans lequel ils
            vivent” - <span className="underline">Alice Parisot</span>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Home

import ProjectCover from '../components/ProjectCover'
import { PROJECTS } from '../utils/config'

const Home = () => (<div className="lg:container px-4 lg:px-16 lg:mx-auto !w-full">
    <div className="grid grid-col-1 lg:grid-cols-2 gap-4 lg:gap-8 !w-full">
      {Object.entries(PROJECTS).map((entry) => (<ProjectCover title={entry[1].name} coverImage={entry[1].cover} projectId={entry[0]}/>))}
    </div>
  </div>)

export default Home

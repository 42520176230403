const PageTitle = ({
  children,
  showDivider = true,
}: {
  children: React.ReactNode
  showDivider?: boolean
}) => (
  <div className="text-2xl uppercase font-semibold p-8 text-center">
    {children}
    {showDivider && <hr className="w-16 mx-auto border-slate-500" />}
  </div>
)

export default PageTitle

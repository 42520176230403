import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import PageTitle from "./PageTitle";
import { PROJECTS, PROJECTS_NAMES } from "../utils/config";

export const ProjectDetail = ({name}: {name: PROJECTS_NAMES}) => {
  console.log('name', name)
    const project = PROJECTS[name]
    console.log(project)

    return (<div className="">
        <div className="py-8">
          <PageTitle>{project.name}</PageTitle>
          <div className="text-center text-sm">
            {project.description}
          </div>
        </div>
        <div className="">
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2}}>
            <Masonry gutter="16px">
              {project.images.map((path, i) => (
                <img key={i} src={path} loading="lazy" />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </div>)
}
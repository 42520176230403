import { useParams } from 'react-router-dom';
import { isValidProjectName } from 'utils/helper';
import { PROJECTS_NAMES } from 'utils/config';
import { ProjectDetail } from 'components/ProjectDetail';

const ProjectDetailPage = () => {
  const urlParams = useParams();
  const projectId = urlParams['projectName']?.toLocaleLowerCase() 

  console.log(projectId)
  if (!projectId || !isValidProjectName(projectId)) return null

  return (<ProjectDetail name={projectId.toLocaleLowerCase() as PROJECTS_NAMES} />)
}

export default ProjectDetailPage
